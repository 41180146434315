import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { authService } from "../services";
import { useBoundStore } from "store";

export const useLogout = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const {
    resetAuthSlice,
    resetProjectSlice,
    resetSubcontractorsSlice,
    resetUserSlice,
  } = useBoundStore();
  const navigate = useNavigate();

  const logout = async () => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);

      await authService.logout();

      setSuccess(true);
      resetAuthSlice();
      resetProjectSlice();
      resetSubcontractorsSlice();
      resetUserSlice();
      navigate("/logout");
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    logout,
    loading,
    success,
    error,
  };
};
