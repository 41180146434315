import { INote } from "notes/models";
import { notesService } from "notes/services";
import { useState } from "react";

export const useGetNotes = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<INote[]>([]);

  const getNotes = async (projectId?: string) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData([]);

      const notes = await notesService.getNotes(projectId);

      setData(notes);
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getNotes,
    loading,
    success,
    error,
    data,
  };
};
