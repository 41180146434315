import { httpService } from "shared/services";
import { INote } from "notes/models";
import { isEmpty } from "lodash";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/notes`;

export const getNotes = async (projectId: string = "") => {
  const query = new URLSearchParams({
    projectId: projectId ?? "",
  }).toString();
  let path = `${apiPath}`;

  if (!isEmpty(query)) {
    path = `${path}?${query}`;
  }
  const res = await httpService.instance.get<INote[]>(path);
  return res?.data;
};
