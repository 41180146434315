import { create } from "zustand";
import {
  StateStorage,
  createJSONStorage,
  devtools,
  persist,
} from "zustand/middleware";

import { createAuthSlice } from "auth/store";
import { createProjectSlice } from "projects/store";
import { createSubcontractorSlice } from "subcontractors/store";
import { createUserSlice } from "users/store";
import { IAuthSlice } from "auth/models";
import { IProjectSlice } from "projects/models";
import { ISubcontractorSlice } from "subcontractors/models";
import { IUserSlice } from "users/models";
import { ISalesSlice } from "sales/models";
import { createSalesSlice } from "sales/store";
import { createFinancialsSlice } from "financials/store";
import { IFinancialsSlice } from "financials/models";
import { createAuditSlice } from "audit/store";
import { IAuditSlice } from "audit/models";

const storage: StateStorage = {
  getItem: (name: string): string | null => {
    return localStorage.getItem(name) || null;
  },
  setItem: (name: string, value: string): void => {
    localStorage.setItem(name, value);
  },
  removeItem: (name: string): void => {
    localStorage.removeItem(name);
  },
};

export const useBoundStore = create<
  IAuthSlice &
    IProjectSlice &
    ISubcontractorSlice &
    IUserSlice &
    ISalesSlice &
    IFinancialsSlice &
    IAuditSlice
>()(
  persist(
    devtools((...a) => ({
      ...createAuthSlice(...a),
      ...createProjectSlice(...a),
      ...createSubcontractorSlice(...a),
      ...createUserSlice(...a),
      ...createSalesSlice(...a),
      ...createFinancialsSlice(...a),
      ...createAuditSlice(...a),
    })),
    {
      name: "tracpro-storage",
      storage: createJSONStorage(() => storage),
    }
  )
);
