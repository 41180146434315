import { IAuditAppointment, IAuditSlice } from "audit/models";
import { StateCreator } from "zustand";

const initialState = {
  auditAppointments: [],
};

export const createAuditSlice: StateCreator<
  IAuditSlice,
  [["zustand/devtools", never]],
  [],
  IAuditSlice
> = (set) => ({
  ...initialState,
  setAuditAppointments: (auditAppointments: IAuditAppointment[]) =>
    set((state) => ({
      auditAppointments: auditAppointments,
    })),
  resetAuditSlice: () => set(() => initialState),
});
