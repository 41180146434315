import { IFieldValidation } from "projects/models";

export const ProjectValidationSchema: IFieldValidation[] = [
  {
    name: "soldQuarter",
    required: {
      stages: [
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldMonth",
    required: {
      stages: [
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldYear",
    required: {
      stages: [
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "reviewedSignedContractDocuments",
    required: {
      stages: [
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "submittedToUtilityForApproval",
    required: {
      stages: [
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "utilityApprovalLetter",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "name",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "address",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "city",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "state",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "zip",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "ownerName",
    required: {
      stages: [],
    },
  },
  {
    name: "ownerEmail",
    required: {
      stages: [],
    },
  },
  {
    name: "ownerPhone",
    required: {
      stages: [],
    },
  },
  {
    name: "onsiteAuditContact",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "onsiteEmail",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "onsitePhone",
    required: {
      stages: [
        "prospecting_leads",
        "ready_for_audit",
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "ownerOrTenantPayee",
    required: {
      stages: [
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "utilityBillsReceivedAndReviewed",
    required: {
      stages: [
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "auditRequestFormReceivedReviewed",
    required: {
      stages: [
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "auditScheduleDate",
    required: {
      stages: [
        "active_auditing",
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "utilityPreInspectionDate",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "hvacQuoteSubsCertsApproved",
    required: {
      stages: [
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "lightingSubcontractorId",
    required: {
      stages: [
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "hvacSubcontractorId",
    required: {
      stages: [
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "proposalSentToSalesRep",
    required: {
      stages: [
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldTotalProjectValue",
    required: {
      stages: [
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldTotalIncentive",
    required: {
      stages: [
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldTotalCustomerPortion",
    required: {
      stages: [
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldLtgValue",
    required: {
      stages: [
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldHvacValue",
    required: {
      stages: [
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "soldRefrigerationValue",
    required: {
      stages: [
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "customerWelcomeLetterSent",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "mechanicalPOSent",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "lightingLaborPOSent",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "lightingMaterialPOSent",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "refrigerationPOSent",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "hvacETA",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "lightingETA",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "refrigerationETA",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "refrigerationInstalled",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "hvacInstalled",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "lightingInstalled",
    required: {
      stages: ["closeout", "billing", "completed"],
    },
  },
  {
    name: "utilityPostInspectionScheduleDate",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "utilityPostInspectionCompleteDate",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "submittedForFinancingApproval",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "financingApproved",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "depositClearedDate",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "billedDepositAmount",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "billedDepositDate",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "depositReceivedAmount",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "depositReceivedDate",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
  {
    name: "billedIncentiveDate",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "billedIncentiveAmount",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "billedFinancedPortionDate",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "billedFinancedPortionAmount",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "billedIncentiveReceivedDate",
    required: {
      stages: ["completed"],
    },
  },
  {
    name: "billedIncentiveReceivedAmount",
    required: {
      stages: ["completed"],
    },
  },
  {
    name: "financedPortionReceivedDate",
    required: {
      stages: ["completed"],
    },
  },
  {
    name: "financedPortionReceivedAmount",
    required: {
      stages: ["completed"],
    },
  },
  {
    name: "overageCustomerPortionReceivedDate",
    required: {
      stages: ["completed"],
    },
  },
  {
    name: "overageCustomerPortionReceivedAmount",
    required: {
      stages: ["completed"],
    },
  },
  {
    name: "overageCustomerPortionDate",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "overageCustomerPortionAmount",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "accrualDate",
    required: {
      stages: ["billing", "completed"],
    },
  },
  {
    name: "accountManagerId",
    required: {
      stages: [],
    },
  },
  {
    name: "leadGeneratorId",
    required: {
      stages: [],
    },
  },
  {
    name: "salesSupportId",
    required: {
      stages: [],
    },
  },
  {
    name: "onsiteAuditDone",
    required: {
      stages: [
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "ceToolDone",
    required: {
      stages: [
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "hvacAssessmentFormDone",
    required: {
      stages: [
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "hvacPrePhotosReceived",
    required: {
      stages: [
        "engineering_prep",
        "engineering_proposal_development",
        "presenting",
        "sold_utility_upload",
        "installation",
        "closeout",
        "billing",
        "completed",
      ],
    },
  },
  {
    name: "utilityPreInspectionDone",
    required: {
      stages: ["installation", "closeout", "billing", "completed"],
    },
  },
];
