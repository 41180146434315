import { SalesTeamSetup, UpdateUserRole } from "admin/components";
import "./UserAdmin.scss";
import { SideNavPageLayout } from "shared/components";
import { Card } from "antd";

export const UserAdmin = () => {
  return (
    <SideNavPageLayout
      title='User Administration'
      defaultSelectedMenuItemKey='admin'
    >
      <Card className='user-admin-container'>
        <UpdateUserRole />
        <div className='user-admin-sales-team-setup-container'>
          <SalesTeamSetup />
        </div>
      </Card>
    </SideNavPageLayout>
  );
};
