import { ISelectOption } from "shared/models";

export const ProjectTypes: ISelectOption[] = [
  {
    label: "Lighting",
    value: "lighting",
  },
  {
    label: "HVAC",
    value: "hvac",
  },
  {
    label: "Refrigeration",
    value: "refrigeration",
  },
];
