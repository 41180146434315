import { AutoComplete } from "antd";

import "./SearchBox.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { useGetProjectOptions } from "projects/hooks";

export const SearchBox = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getProjectOptions, data } = useGetProjectOptions();

  const handleSearch = (value: string) => {
    if (value?.length < 3) return;
    getProjectOptions(value);
  };

  const handleSelect = (value: string, option: any) => {
    const url = `/projects${isEmpty(option?.id) ? "" : `?id=${option?.id}`}`;
    navigate(url);

    if (url.includes(location.pathname)) {
      navigate(0);
    }
  };

  return (
    <AutoComplete
      className='search-box-input-container'
      options={data}
      onSearch={handleSearch}
      onSelect={handleSelect}
      placeholder='Search here...'
      size='large'
    ></AutoComplete>
  );
};
