import { startCase } from "lodash";
import { ISelectOption } from "shared/models";

export enum UserRoles {
  Owner = "owner",
  ProgramManager = "program_manager",
  AuditAdministrator = "audit_admin",
  Contractor = "contractor",
  Engineer = "engineer",
  OperationsManager = "operations_manager",
  FinanceManager = "finance_manager",
  SalesRep = "sales_rep",
  FieldAuditor = "field_auditor",
  Subcontractor = "subcontractor",
}

export const UserRoleSelectOptions = () => {
  let options: ISelectOption[] = [];

  for (const key in UserRoles) {
    if (Object.prototype.hasOwnProperty.call(UserRoles, key)) {
      options.push({
        label: startCase(key),
        value: (UserRoles as any)[key],
      });
    }
  }

  return options;
};
