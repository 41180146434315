import React, { useEffect } from "react";
import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import { Table, TableProps } from "antd";

import "./ProjectList.scss";
import { ProjectFilters } from "../project-filters/ProjectFilters";
import { IProject } from "projects/models";
import { useGetProjects } from "projects/hooks";
import { ProjectColumns } from "projects/constants";
import { ProjectListForm } from "..";
import { useBoundStore } from "store";
import { useSearchParams } from "react-router-dom";

export const ProjectList = () => {
  let [searchParams] = useSearchParams();
  const tblRef: Parameters<typeof Table>[0]["ref"] = React.useRef(null);
  const { loading, data, getProjects } = useGetProjects();
  const { filters, stage, showArchived } = useBoundStore();
  const defaultProjectId = searchParams?.get("id")?.trim() ?? "";
  const expandable = {
    expandedRowRender: (record: IProject) => (
      <ProjectListForm project={record} onFinish={onFilterChange} />
    ),
    expandIcon: ({ expanded, onExpand, record }: any) =>
      expanded ? (
        <CaretDownFilled
          className='project-list-caret-icon'
          onClick={(e) => onExpand(record, e)}
        />
      ) : (
        <CaretRightFilled
          className='project-list-caret-icon'
          onClick={(e) => onExpand(record, e)}
        />
      ),
    defaultExpandedRowKeys: [defaultProjectId],
  };
  const tableColumns = ProjectColumns.map((item) => ({ ...item }));
  const tableProps: TableProps<IProject> = {
    loading,
    expandable,
  };
  const onFilterChange = async () => {
    if (filters) {
      const params = {
        status: filters.projectStatus.join(","),
        subcontractor: filters.subcontractor.join(","),
        utilityProgram: filters.utilityProgram.join(","),
        salesRep: filters.salesRep.join(","),
        customerPaymentMethod: filters.customerPaymentMethod.join(","),
        stage: stage,
        showArchived: showArchived.toString(),
      };
      const query = new URLSearchParams(params).toString();
      await getProjects(query);
    }
  };

  useEffect(() => {
    onFilterChange();
  }, [filters, stage, showArchived]);

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <ProjectFilters />
      <Table
        {...tableProps}
        columns={tableColumns}
        dataSource={data}
        className='project-list-table'
        ref={tblRef}
        rowKey='id'
      />
    </>
  );
};
