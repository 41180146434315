import { ISelectOption } from "shared/models";

export const CustomerPaymentMethods: ISelectOption[] = [
  {
    label: "NEIF 0% financing",
    value: "neif_0_financing",
  },
  {
    label: "On-Bill financing",
    value: "on_bill_financing",
  },
  {
    label: "Direct-To-Contractor",
    value: "direct_to_contractor",
  },
];
