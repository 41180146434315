import { ISimpleSubcontractor } from "subcontractors/models";
import { httpService } from "shared/services";

const apiPath = `${process.env.REACT_APP_API_BASE_URL}/api/v1/subcontractors`;

export const getSimpleSubcontractorsByType = async (type: string) => {
  const res = await httpService.instance.get<ISimpleSubcontractor[]>(
    `${apiPath}/simple?type=${type}`
  );
  return res?.data;
};

export const getSimpleSubcontractors = async () => {
  const res = await httpService.instance.get<ISimpleSubcontractor[]>(
    `${apiPath}/simple`
  );
  return res?.data;
};
