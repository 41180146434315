import { valueType } from "antd/es/statistic/utils";
import dayjs from "dayjs";
import { isEmpty, truncate } from "lodash";

export const truncateField = (f: string) => truncate(f, { length: 100 });

export const statisticsFormatter = (value: valueType) =>
  new Intl.NumberFormat("en-US").format(Number(value));

export const dateFormatter = (date: Date) =>
  isEmpty(date) ? "N/A" : dayjs(date).format("MM/DD/YYYY");

export const timeFormatter = (date: Date) =>
  isEmpty(date) ? "N/A" : dayjs(date).format("hh:mm A");

export const phoneNumberFormatter = (phone: string) =>
  phone?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
