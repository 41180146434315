import { Form, Input, notification, Row, Typography } from "antd";
import { useEffect } from "react";

import "./SalesTargets.scss";
import { useUpdateUserConfiguration } from "users/hooks";
import { useBoundStore } from "store";
import { CaretDownFilled } from "@ant-design/icons";

type Props = {
  name: string;
};

export const SalesTargets = ({ name }: Props) => {
  const [form] = Form.useForm();
  const { updateUserConfiguration, error } = useUpdateUserConfiguration();
  const [api, notificationContextHolder] = notification.useNotification();
  const { user } = useBoundStore();

  const onBlurHandler = () => {
    const salesTargetAmount = form.getFieldValue("salesTargetAmount");
    const billedProjectsTargetAmount = form.getFieldValue(
      "billedProjectsTargetAmount"
    );
    const completedProjectsTargetAmount = form.getFieldValue(
      "completedProjectsTargetAmount"
    );

    updateUserConfiguration({
      salesTargetAmount,
      billedProjectsTargetAmount,
      completedProjectsTargetAmount,
    });
  };

  useEffect(() => {
    if (error) {
      api.error({
        message: "Update user sales target error",
        description: error?.message,
      });
    }
  }, [error, api]);

  return (
    <div className='sales-targets-container'>
      {notificationContextHolder}
      <Form
        form={form}
        name={`${name}_form`}
        data-testid={`${name}_testid`}
        requiredMark={false}
        layout='vertical'
        initialValues={{
          salesTargetAmount: user?.configuration?.salesTargetAmount ?? 0,
          billedProjectsTargetAmount:
            user?.configuration?.billedProjectsTargetAmount ?? 0,
          completedProjectsTargetAmount:
            user?.configuration?.completedProjectsTargetAmount ?? 0,
        }}
        onBlur={onBlurHandler}
      >
        <Row>
          <Typography.Title className='sales-targets-title' level={3}>
            Enter Targets
          </Typography.Title>
          <CaretDownFilled className='sales-targets-icon' />
        </Row>
        <Row justify='start'>
          <Form.Item
            name='salesTargetAmount'
            label='Sales'
            data-testid={`${name}_salesTargetAmount_testid`}
          >
            <Input size='small' type='number' variant='borderless' />
          </Form.Item>
          <Form.Item
            name='billedProjectsTargetAmount'
            label='Billed Projects'
            data-testid={`${name}_billedProjectsTargetAmount_testid`}
          >
            <Input size='small' type='number' variant='borderless' />
          </Form.Item>
          <Form.Item
            name='completedProjectsTargetAmount'
            label='Completed Projects'
            data-testid={`${name}_completedProjectsTargetAmount_testid`}
          >
            <Input size='small' type='number' variant='borderless' />
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};
