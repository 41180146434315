import {
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from "antd";

import "./ProjectSalesInformation.scss";
import { useBoundStore } from "store";
import { ProjectFormFieldItem } from "../project-form-field-item/ProjectFormFieldItem";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { useDisplayFieldByStage } from "projects/hooks";

type Props = {
  form: FormInstance;
};

const fieldCategory = "salesInformationFields";

export const ProjectSalesInformation = ({ form }: Props) => {
  const { salesRepsSelectOptions } = useBoundStore();
  const validationStage = Form.useWatch("stage", form);
  const { displayFieldCategoryByStage } = useDisplayFieldByStage();

  return displayFieldCategoryByStage(fieldCategory) ? (
    <div className='project-sales-information-container'>
      <Flex>
        <Typography.Title className='project-sales-information-title' level={2}>
          Sales Information
        </Typography.Title>
      </Flex>
      <Divider className='project-sales-information-divider' />
      <Row justify='start'>
        <ProjectFormFieldItem
          name='accountManagerId'
          label='Account Manager'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              ...salesRepsSelectOptions,
            ]}
            onSelect={(value) => {
              form.setFieldValue("accountManagerId", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='accountManagerCommissionRate'
          label='Account Manager Commission Rate'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='accountManagerCommissionAmount'
          label='Account Manager Commission Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='leadGeneratorId'
          label='Lead Generator'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              ...salesRepsSelectOptions,
            ]}
            onSelect={(value) => {
              form.setFieldValue("leadGeneratorId", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='leadGeneratorCommissionRate'
          label='Lead Generator Commission Rate'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='leadGeneratorCommissionAmount'
          label='Lead Generator Commission Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='salesSupportId'
          label='Sales Support'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              ...salesRepsSelectOptions,
            ]}
            onSelect={(value) => {
              form.setFieldValue("salesSupportId", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='salesSupportCommissionRate'
          label='Sales Support Commission Rate'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='salesSupportCommissionAmount'
          label='Sales Support Commission Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' readOnly disabled />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='commissionPaid'
          label='Commission Paid'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "commissionPaid",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <Col span={4}></Col>
        <Col span={4}></Col>
      </Row>
    </div>
  ) : null;
};
