import { StateCreator } from "zustand";

import {
  IPipelineDetails,
  ISalesByUtility,
  ISalesCards,
  ISalesLeaderboard,
  ISalesPipelineProjects,
  ISalesSlice,
  ITargetVsActualSales,
} from "sales/models";
import { IProjectByStage } from "projects/models";

const initialState = {
  salesLeaderboard: [],
  salesByUtility: [],
  targetVsActualSales: [],
  salesCards: {
    targetSales: 0,
    actualSales: 0,
    newSales: 0,
  },
  pipelineDetails: [],
  pipelineByProjectStages: [],
  salesPipelineProjects: {
    totalProjects: 0,
    totalProjectsValue: 0,
    totalProjectsClosingInThirtyDays: 0,
  },
};

export const createSalesSlice: StateCreator<
  ISalesSlice,
  [["zustand/devtools", never]],
  [],
  ISalesSlice
> = (set) => ({
  ...initialState,
  setSalesLeaderboard: (salesLeaderboard: ISalesLeaderboard[]) =>
    set((state) => ({
      salesLeaderboard: salesLeaderboard,
    })),
  setSalesByUtility: (salesByUtility: ISalesByUtility[]) =>
    set((state) => ({
      salesByUtility: salesByUtility,
    })),
  setTargetVsActualSales: (targetVsActualSales: ITargetVsActualSales[]) =>
    set((state) => ({
      targetVsActualSales: targetVsActualSales,
    })),
  setPipelineDetails: (pipelineDetails: IPipelineDetails[]) =>
    set((state) => ({
      pipelineDetails: pipelineDetails,
    })),
  setPipelineByProjectStages: (pipelineByProjectStages: IProjectByStage[]) =>
    set((state) => ({
      pipelineByProjectStages: pipelineByProjectStages,
    })),
  setSalesCards: (salesCards: ISalesCards) =>
    set((state) => ({
      salesCards: salesCards,
    })),
  setSalesPipelineProjects: (salesPipelineProjects: ISalesPipelineProjects) =>
    set((state) => ({
      salesPipelineProjects: salesPipelineProjects,
    })),
  resetSalesSlice: () => set(() => initialState),
});
