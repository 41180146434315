import {
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from "antd";

import "./ProjectInstallationInformation.scss";
import { useBoundStore } from "store";
import { ProjectFormFieldItem } from "../project-form-field-item/ProjectFormFieldItem";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { useDisplayFieldByStage } from "projects/hooks";

type Props = {
  form: FormInstance;
};

const fieldCategory = "installationInformationFields";

export const ProjectInstallationInformation = ({ form }: Props) => {
  const {
    lightingSubcontractorsSelectOptions,
    hvacSubcontractorsSelectOptions,
    refrigerationSubcontractorsSelectOptions,
  } = useBoundStore();
  const validationStage = Form.useWatch("stage", form);
  const { displayFieldCategoryByStage } = useDisplayFieldByStage();

  return displayFieldCategoryByStage(fieldCategory) ? (
    <div className='project-installation-information-container'>
      <Flex>
        <Typography.Title
          className='project-installation-information-title'
          level={2}
        >
          Installation Information
        </Typography.Title>
      </Flex>
      <Divider className='project-installation-information-divider' />
      <Row justify='start'>
        <ProjectFormFieldItem
          name='lightingSubcontractorId'
          label='Lighting Sub'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              ...lightingSubcontractorsSelectOptions,
            ]}
            onSelect={(value) => {
              form.setFieldValue("lightingSubcontractorId", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='hvacSubcontractorId'
          label='HVAC Sub'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              ...hvacSubcontractorsSelectOptions,
            ]}
            onSelect={(value) => {
              form.setFieldValue("hvacSubcontractorId", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='refrigerationSubcontractorId'
          label='Ref Sub'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              ...refrigerationSubcontractorsSelectOptions,
            ]}
            onSelect={(value) => {
              form.setFieldValue("refrigerationSubcontractorId", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='customerWelcomeLetterSent'
          label='Customer Welcome Letter Sent'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "customerWelcomeLetterSent",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='mechanicalPOSent'
          label='Mechanical PO Sent'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "mechanicalPOSent",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='lightingLaborPOSent'
          label='Ltg. Labor PO Sent'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "lightingLaborPOSent",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='lightingMaterialPOSent'
          label='Ltg. Material PO Sent'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "lightingMaterialPOSent",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='refrigerationPOSent'
          label='Ref PO Sent'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "refrigerationPOSent",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='refrigerationETA'
          label='Refrigeration ETA'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "refrigerationETA",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='hvacETA'
          label='HVAC ETA'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue("hvacETA", isEmpty(x) ? null : dayjs(x));
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='lightingETA'
          label='Ltg. ETA'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue("lightingETA", isEmpty(x) ? null : dayjs(x));
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='refrigerationInstalled'
          label='Ref Installed'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "refrigerationInstalled",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='hvacInstalled'
          label='HVAC Installed'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue("hvacInstalled", isEmpty(x) ? null : dayjs(x));
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='lightingInstalled'
          label='Ltg. Installed'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "lightingInstalled",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='lightingMaterialPOAmount'
          label='Ltg Material PO Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='lightingLaborPOAmount'
          label='Ltg Labor PO Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='mechanicalPOAmount'
          label='Mechanical PO Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='refrigerationPOAmount'
          label='Refrigeration PO Amount'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='completionDocumentSentToCustomer'
          label='Completion Document Sent to Customer'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "completionDocumentSentToCustomer",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='closeoutPackageSubmittedToUtility'
          label='Closeout Package Submitted to Utility'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "closeoutPackageSubmittedToUtility",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='utilityPostInspectionScheduleDate'
          label='Utility Post Inspection Schedule Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "utilityPostInspectionScheduleDate",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='utilityPostInspectionCompleteDate'
          label='Utility Post Inspection Complete Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "utilityPostInspectionCompleteDate",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <Col span={4}></Col>
        <Col span={4}></Col>
      </Row>
    </div>
  ) : null;
};
