import { Card, Col, Progress, Row, Typography } from "antd";

import { useBoundStore } from "store";

import "./DeficientProjects.scss";
import { MessageFilled } from "@ant-design/icons";

export const DeficientProjects = () => {
  const { deficientProjects } = useBoundStore();

  return (
    <>
      <Row>
        <Typography.Title className='deficient-projects-title' level={3}>
          Deficient Projects
        </Typography.Title>
      </Row>
      <Row
        gutter={[
          { xs: 4, sm: 8, md: 16, lg: 20 },
          { xs: 4, sm: 8, md: 16, lg: 20 },
        ]}
      >
        {deficientProjects?.map((p) => (
          <Col span={6}>
            <Card className='deficient-projects-card'>
              <Row>
                <Col span={24}>
                  <Typography.Text className='deficient-projects-card-title'>
                    {p.name}
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Typography.Text className='deficient-projects-card-subtitle'>
                    {p.mmProjectId}
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Typography.Text className='deficient-projects-card-note'>
                    <MessageFilled /> {p.latestProjectNote?.content}
                  </Typography.Text>
                </Col>
              </Row>
              <Row
                gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}
                justify={"space-between"}
              >
                <Col span={24}>
                  <Progress
                    percent={p.percent}
                    showInfo={false}
                    status='exception'
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Typography.Text>
                    <Typography.Text strong>{p.daysOverdue}</Typography.Text>{" "}
                    Days Overdue
                  </Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Typography.Text>
                    <Typography.Text strong>Stage:</Typography.Text> {p.stage}
                  </Typography.Text>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
