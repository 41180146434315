import {
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography,
} from "antd";

import "./ProjectInformation.scss";
import {
  CustomerPaymentMethods,
  ProjectTypes,
  UtilityPrograms,
} from "projects/constants";
import { ProjectFormFieldItem } from "../project-form-field-item/ProjectFormFieldItem";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useDisplayFieldByStage } from "projects/hooks";
import { tagRender } from "shared/components";

type Props = {
  form: FormInstance;
};

const fieldCategory = "projectInformationFields";

export const ProjectInformation = ({ form }: Props) => {
  const validationStage = Form.useWatch("stage", form);
  const { displayFieldCategoryByStage } = useDisplayFieldByStage();

  return displayFieldCategoryByStage(fieldCategory) ? (
    <div className='project-information-container'>
      <Flex>
        <Typography.Title className='project-information-title' level={2}>
          Project Information
        </Typography.Title>
      </Flex>
      <Divider className='project-information-divider' />
      <Row justify='start'>
        <ProjectFormFieldItem
          name='projectTypes'
          label='Project Type(s)'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            mode='multiple'
            tagRender={tagRender}
            options={ProjectTypes}
            className='multi-select-box'
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='utilityProgram'
          label='Utility Program'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[{ value: "", label: "Select..." }, ...UtilityPrograms]}
            onSelect={(value) => {
              form.setFieldValue("utilityProgram", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='customerPaymentMethod'
          label='Customer Payment Method'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              ...CustomerPaymentMethods,
            ]}
            onSelect={(value) => {
              form.setFieldValue("customerPaymentMethod", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='ownerOrTenantPayee'
          label='Owner or Tenant Payee'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Select
            size='small'
            variant='borderless'
            options={[
              { value: "", label: "Select..." },
              { value: "Owner", label: "Owner" },
              { value: "Tenant Payee", label: "Tenant Payee" },
            ]}
            onSelect={(value) => {
              form.setFieldValue("ownerOrTenantPayee", value);
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='utilityBillsReceivedAndReviewed'
          label='Utility Bills Received and Reviewed'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "utilityBillsReceivedAndReviewed",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='auditRequestFormReceivedReviewed'
          label='Audit Request Form Received/Reviewed'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "auditRequestFormReceivedReviewed",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='auditScheduleDate'
          label='Audit Schedule Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "auditScheduleDate",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='onsiteAuditDone'
          label='Onsite Audit Done'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "onsiteAuditDone",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='ceToolDone'
          label='CE Tool Done'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue("ceToolDone", isEmpty(x) ? null : dayjs(x));
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='hvacAssessmentFormDone'
          label='HVAC Assessment Form Done'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "hvacAssessmentFormDone",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='hvacPrePhotosReceived'
          label='HVAC Pre-photos Received'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "hvacPrePhotosReceived",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='hvacQuoteSubsCertsApproved'
          label='HVAC Quote/Subs/Certs Approved'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "hvacQuoteSubsCertsApproved",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='proposalSentToSalesRep'
          label='Proposal Sent To Sales Rep'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "proposalSentToSalesRep",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='estimatedSaleDate'
          label='Esitmated Sale Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "estimatedSaleDate",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldQuarter'
          label='Sold Quarter'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' type='number' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldMonth'
          label='Sold Month'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' type='number' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='soldYear'
          label='Sold Year'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
        >
          <Input size='small' type='number' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='reviewedSignedContractDocuments'
          label='Reviewed Signed Contract Documents'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "reviewedSignedContractDocuments",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='submittedToUtilityForApproval'
          label='Submitted to Utility for Approval'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "submittedToUtilityForApproval",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='submittedForFinancingApproval'
          label='Submitted for Financial Approval'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "submittedForFinancingApproval",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='financingApproved'
          label='Financing Approved'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "financingApproved",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='utilityPreInspectionDate'
          label='Utility Pre-Inspection Date'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "utilityPreInspectionDate",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='utilityPreInspectionDone'
          label='Utility Pre-Inspection Done'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "utilityPreInspectionDone",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='utilityApprovalLetter'
          label='Utility Approval Letter'
          fieldCategory={fieldCategory}
          colSpan={4}
          validationStage={validationStage}
          getValueProps={(i) => ({ value: isEmpty(i) ? null : dayjs(i) })}
        >
          <DatePicker
            allowClear
            size='small'
            variant='borderless'
            onChange={(x) => {
              form.setFieldValue(
                "utilityApprovalLetter",
                isEmpty(x) ? null : dayjs(x)
              );
            }}
          />
        </ProjectFormFieldItem>
        <Col span={4}></Col>
      </Row>
    </div>
  ) : null;
};
