import {
  Form,
  Select,
  Typography,
  Image,
  Space,
  Col,
  Row,
  Skeleton,
  Button,
  notification,
  Input,
  Modal,
} from "antd";
import selectProjectStage from "../../assets/images/select-project-stage.svg";
import "./SalesTeamSetup.scss";
import { useEffect, useState } from "react";
import {
  useDeleteSalesTeam,
  useGetSalesTeams,
  useUpsertSalesTeam,
} from "admin/hooks";
import { SubmitButton } from "shared/components";
import { useBoundStore } from "store";
import { isEmpty } from "lodash";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const SalesTeamSetup = () => {
  const { salesRepsSelectOptions } = useBoundStore();
  const useGetSalesTeamsHook = useGetSalesTeams();
  const useUpsertSalesTeamHook = useUpsertSalesTeam();
  const useDeleteSalesTeamHook = useDeleteSalesTeam();
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [existingTeamDisabled, setExistingTeamDisabled] = useState(false);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const [api, notificationContextHolder] = notification.useNotification();

  const onFinishHandler = async (values: any) => {
    const id = values?.team?.trim();
    const name = values?.name?.trim();
    const accountManagerId = values?.accountManagerId?.trim();
    const accountManagerCommissionRate =
      values?.accountManagerCommissionRate ?? 0;
    const leadGeneratorId = values?.leadGeneratorId?.trim();
    const leadGeneratorCommissionRate =
      values?.leadGeneratorCommissionRate ?? 0;
    const salesSupportId = values?.salesSupportId?.trim();
    const salesSupportCommissionRate = values?.salesSupportCommissionRate ?? 0;

    await useUpsertSalesTeamHook.upsertSalesTeam({
      id,
      name,
      accountManagerId,
      accountManagerCommissionRate,
      leadGeneratorId,
      leadGeneratorCommissionRate,
      salesSupportId,
      salesSupportCommissionRate,
    });

    form.resetFields();
  };

  const onDeleteHandler = async () => {
    modal.confirm({
      title: "Confirm Sales Team Delete",
      content: "Are you sure you want to delete this sales team?",
      okText: "Delete",
      okButtonProps: {
        type: "default",
      },
      icon: <ExclamationCircleOutlined />,
      cancelText: "Cancel",
      cancelButtonProps: {
        type: "default",
      },
      onOk: async () => {
        const team = form?.getFieldValue("team")?.trim();
        await useDeleteSalesTeamHook.deleteSalesTeam(team);
        form.resetFields();
      },
    });
  };

  useEffect(() => {
    const run = async () => {
      await useGetSalesTeamsHook?.getSalesTeams();
    };

    run();
  }, []);

  useEffect(() => {
    if (useUpsertSalesTeamHook.success) {
      api.success({
        duration: 3,
        message: "Sales Team Saved",
        description: "The sales team has been saved.",
      });
      useGetSalesTeamsHook?.getSalesTeams();
    }

    if (useUpsertSalesTeamHook.error) {
      api.error({
        message: "Error",
        description: useUpsertSalesTeamHook.error?.message,
      });
    }
  }, [useUpsertSalesTeamHook.success, useUpsertSalesTeamHook.error, api]);

  useEffect(() => {
    if (useDeleteSalesTeamHook.success) {
      api.success({
        duration: 3,
        message: "User Deleted",
        description: "The user has been deleted.",
      });
      useGetSalesTeamsHook?.getSalesTeams();
    }

    if (useDeleteSalesTeamHook.error) {
      api.error({
        message: "Error",
        description: useDeleteSalesTeamHook.error?.message,
      });
    }
  }, [useDeleteSalesTeamHook.success, useDeleteSalesTeamHook.error, api]);

  return (
    <>
      {notificationContextHolder}
      {contextHolder}
      {useGetSalesTeamsHook?.success ? (
        <>
          <Typography.Title className='sales-team-setup-title'>
            Sales Team Setup
          </Typography.Title>
          <Typography.Paragraph>
            <ol type='1'>
              <li>Add a sales new team by entering a team name</li>
              <li>
                OR, Select an existing sales team using the drop down menu
              </li>
              <li>Select individuals for each role</li>
              <li>Assign a commission rate to each role</li>
              <li>Click “Save sales team”</li>
            </ol>
          </Typography.Paragraph>
          <Form
            form={form}
            name='sales-team-setup-form'
            data-testid='sales-team-setup-form'
            layout='vertical'
            onFinish={onFinishHandler}
            requiredMark={false}
            initialValues={{
              name: "",
              team: "",
              accountManagerId: "",
              accountManagerCommissionRate: 0,
              leadGeneratorId: "",
              leadGeneratorCommissionRate: 0,
              salesSupportId: "",
              salesSupportCommissionRate: 0,
            }}
          >
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={8}>
                <Form.Item
                  name='name'
                  data-testid='name'
                  className='sales-team-setup-input'
                  labelAlign='left'
                  label={
                    <>
                      <Space>
                        <Image
                          src={selectProjectStage}
                          alt='New Team'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='sales-team-setup-title-small'>
                          Enter New Team Name
                        </Typography.Text>
                      </Space>
                    </>
                  }
                >
                  <Input
                    size='large'
                    onChange={(element) => {
                      const empty = isEmpty(element?.target?.value);

                      if (!empty && !existingTeamDisabled) {
                        form.setFieldsValue({
                          team: "",
                          accountManagerId: "",
                          accountManagerCommissionRate: 0,
                          leadGeneratorId: "",
                          leadGeneratorCommissionRate: 0,
                          salesSupportId: "",
                          salesSupportCommissionRate: 0,
                        });
                        setExistingTeamDisabled(true);
                        setDeleteButtonDisabled(true);
                      } else if (empty && existingTeamDisabled) {
                        setExistingTeamDisabled(false);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2} className='sales-team-setup-text'>
                <Typography.Paragraph>OR</Typography.Paragraph>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='team'
                  data-testid='team'
                  className='sales-team-setup-select-box'
                  labelAlign='left'
                  label={
                    <>
                      <Space>
                        <Image
                          src={selectProjectStage}
                          alt='Existing Sales Team'
                          preview={false}
                          width={22}
                        />
                        <Typography.Text className='sales-team-setup-title-small'>
                          Select Existing Sales Team
                        </Typography.Text>
                      </Space>
                    </>
                  }
                >
                  <Select
                    key={existingTeamDisabled.toString()}
                    disabled={existingTeamDisabled}
                    defaultValue={""}
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...(useGetSalesTeamsHook?.data?.map((t) => ({
                        value: t.id,
                        label: t.name,
                      })) ?? []),
                    ]}
                    onSelect={(value) => {
                      const team = useGetSalesTeamsHook?.data?.find(
                        (t) => t.id === value
                      );

                      form.setFieldsValue({
                        team: value,
                        accountManagerId: team?.accountManagerId,
                        accountManagerCommissionRate:
                          team?.accountManagerCommissionRate,
                        leadGeneratorId: team?.leadGeneratorId,
                        leadGeneratorCommissionRate:
                          team?.leadGeneratorCommissionRate,
                        salesSupportId: team?.salesSupportId,
                        salesSupportCommissionRate:
                          team?.salesSupportCommissionRate,
                      });

                      setDeleteButtonDisabled(false);
                    }}
                  />{" "}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={6}>
                <Form.Item
                  name='accountManagerId'
                  label='Account Manager'
                  rules={[
                    {
                      required: true,
                      message: "Account Manager is required.",
                    },
                  ]}
                >
                  <Select
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...salesRepsSelectOptions,
                    ]}
                    onSelect={(value) => {
                      form.setFieldValue("accountManagerId", value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  name='accountManagerCommissionRate'
                  label='Rate'
                  className='sales-team-setup-input'
                  rules={[
                    {
                      required: true,
                      message: "Rate is required.",
                    },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='leadGeneratorId'
                  label='Lead Generator'
                  rules={[
                    {
                      required: true,
                      message: "Lead Generator is required.",
                    },
                  ]}
                >
                  <Select
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...salesRepsSelectOptions,
                    ]}
                    onSelect={(value) => {
                      form.setFieldValue("leadGeneratorId", value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  name='leadGeneratorCommissionRate'
                  label='Rate'
                  className='sales-team-setup-input'
                  rules={[
                    {
                      required: true,
                      message: "Rate is required.",
                    },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name='salesSupportId'
                  label='Sales Support'
                  rules={[
                    {
                      required: true,
                      message: "Sales Support is required.",
                    },
                  ]}
                >
                  <Select
                    size='large'
                    options={[
                      { value: "", label: "Select..." },
                      ...salesRepsSelectOptions,
                    ]}
                    onSelect={(value) => {
                      form.setFieldValue("salesSupportId", value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  name='salesSupportCommissionRate'
                  label='Rate'
                  className='sales-team-setup-input'
                  rules={[
                    {
                      required: true,
                      message: "Rate is required.",
                    },
                  ]}
                >
                  <Input size='large' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 4, sm: 8, md: 16, lg: 20 }}>
              <Col span={5}>
                <SubmitButton
                  className='sales-team-setup-form-submit-button'
                  testId='sales-team-setup-form-submit-button'
                  form={form}
                >
                  Save Sales Team
                </SubmitButton>
              </Col>
              <Col span={5}>
                <Button
                  danger
                  type='primary'
                  size='large'
                  block
                  className='sales-team-setup-form-delete-button'
                  onClick={onDeleteHandler}
                  disabled={deleteButtonDisabled}
                >
                  Delete Sales Team
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};
