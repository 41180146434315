import { ISelectOption } from "shared/models";

export const ProjectStatuses: ISelectOption[] = [
  {
    label: "In progress",
    value: "in_progress",
  },
  {
    label: "Deficient",
    value: "deficient",
  },
  {
    label: "Archived",
    value: "archived",
  },
];
