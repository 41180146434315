import {
  Col,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Typography,
} from "antd";

import "./ProjectContactInformation.scss";
import { ProjectFormFieldItem } from "../project-form-field-item/ProjectFormFieldItem";
import { useDisplayFieldByStage } from "projects/hooks";

const fieldCategory = "contactInformationFields";

type Props = {
  form: FormInstance;
};

export const ProjectContactInformation = ({ form }: Props) => {
  const validationStage = Form.useWatch("stage", form);
  const { displayFieldCategoryByStage } = useDisplayFieldByStage();

  return displayFieldCategoryByStage(fieldCategory) ? (
    <div className='project-contact-information-container'>
      <Flex>
        <Typography.Title
          className='project-contact-information-title'
          level={2}
        >
          Contact Information
        </Typography.Title>
      </Flex>
      <Divider className='project-contact-information-divider' />
      <Row justify='start'>
        <ProjectFormFieldItem
          name='address'
          label='Address'
          fieldCategory={fieldCategory}
          colSpan={6}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='ownerName'
          label='Owner Name'
          fieldCategory={fieldCategory}
          colSpan={6}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='ownerEmail'
          label='Owner Email'
          fieldCategory={fieldCategory}
          colSpan={6}
          validationStage={validationStage}
          rules={[
            {
              type: "email",
              message: "Please enter a valid email address.",
            },
          ]}
        >
          <Input size='small' type='email' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='ownerPhone'
          label='Owner Phone'
          fieldCategory={fieldCategory}
          colSpan={6}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>

        <Col span={6}>
          <Row justify='space-between'>
            <ProjectFormFieldItem
              name='city'
              label='City'
              fieldCategory={fieldCategory}
              colSpan={10}
              validationStage={validationStage}
            >
              <Input size='small' />
            </ProjectFormFieldItem>
            <ProjectFormFieldItem
              name='state'
              label='State'
              fieldCategory={fieldCategory}
              colSpan={6}
              validationStage={validationStage}
            >
              <Input size='small' />
            </ProjectFormFieldItem>
            <ProjectFormFieldItem
              name='zip'
              label='Zip'
              fieldCategory={fieldCategory}
              colSpan={6}
              validationStage={validationStage}
            >
              <Input size='small' />
            </ProjectFormFieldItem>
          </Row>
        </Col>
        <ProjectFormFieldItem
          name='onsiteAuditContact'
          label='Onsite Audit Contact'
          fieldCategory={fieldCategory}
          colSpan={6}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='onsiteEmail'
          label='Onsite Email'
          fieldCategory={fieldCategory}
          colSpan={6}
          validationStage={validationStage}
          rules={[
            {
              type: "email",
              message: "Please enter a valid email address.",
            },
          ]}
        >
          <Input size='small' type='email' />
        </ProjectFormFieldItem>
        <ProjectFormFieldItem
          name='onsitePhone'
          label='Onsite Phone'
          fieldCategory={fieldCategory}
          colSpan={6}
          validationStage={validationStage}
        >
          <Input size='small' />
        </ProjectFormFieldItem>
      </Row>
    </div>
  ) : null;
};
