import { StateCreator } from "zustand";

import { ISelectOption } from "shared/models";
import { IAuthSlice } from "../models";

const initialState = {
  salesRepsSelectOptions: [],
  token: "",
};

export const createAuthSlice: StateCreator<
  IAuthSlice,
  [["zustand/devtools", never]],
  [],
  IAuthSlice
> = (set) => ({
  ...initialState,
  setSalesRepsSelectOptions: (selectOptions: ISelectOption[]) =>
    set(() => ({
      salesRepsSelectOptions: selectOptions,
    })),
  setToken: (token) =>
    set(() => ({
      token,
    })),
  resetAuthSlice: () => set(() => initialState),
});
