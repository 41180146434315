import { GetProp, TableProps, Typography } from "antd";
import { startCase } from "lodash";

import { IProject } from "projects/models";
import { UtilityPrograms } from "./UtilityPrograms";
import { utilityService } from "shared/services";
import { ISelectOption } from "shared/models";

type ColumnsType<T extends object> = GetProp<TableProps<T>, "columns">;

export const ProjectColumns: ColumnsType<IProject> = [
  {
    title: "",
    dataIndex: "id",
    width: 100,
    fixed: "left",
    render: (value, record, index) => index + 1,
  },
  {
    title: "Utility Project ID",
    dataIndex: "utilityProjectId",
    sorter: (a, b) =>
      (a.utilityProjectId ?? "").localeCompare(b.utilityProjectId ?? ""),
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "M&M Project ID",
    dataIndex: "mmProjectId",
    sorter: (a, b) => (a.mmProjectId ?? "").localeCompare(b.mmProjectId ?? ""),
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Project Name",
    dataIndex: "name",
    sorter: (a, b) => (a.name ?? "").localeCompare(b.name ?? ""),
    render: (value, record, index) => utilityService.truncateField(value),
  },
  {
    title: "Utility",
    dataIndex: "utilityProgram",
    render: (value, record, index) => {
      const program = UtilityPrograms.find(
        (p: ISelectOption) =>
          p.value?.toString().toLowerCase() === value?.toLowerCase()
      );
      return utilityService.truncateField(program?.label?.toString() ?? "");
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value, record, index) => {
      const label = utilityService.truncateField(startCase(value));
      if (value?.toLowerCase() === "deficient")
        return <Typography.Text type='danger'>{label}</Typography.Text>;
      return <Typography.Text>{label}</Typography.Text>;
    },
  },
  {
    title: "Latest Project Note",
    dataIndex: "latestProjectNote",
    render: (value, record, index) =>
      utilityService.truncateField(value?.content),
  },
];
