import { ISelectOption } from "shared/models";

export const UtilityPrograms: ISelectOption[] = [
  {
    label: "JCPL (NJ)",
    value: "jcpl_nj",
  },
  {
    label: "PSEG (NJ)",
    value: "pseg_nj",
  },
  {
    label: "NJNG (NJ)",
    value: "njng_nj",
  },
  {
    label: "O&R (NJ)",
    value: "or_nj",
  },
  {
    label: "ConEd (NY)",
    value: "con_ed_ny",
  },
  {
    label: "Eversource (CT)",
    value: "eversource_ct",
  },
  {
    label: "TBD",
    value: "tbd",
  },
];
