import {
  Avatar,
  Button,
  Col,
  Image,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import {
  HomeFilled,
  MenuOutlined,
  PlusCircleFilled,
  ProjectFilled,
  UserOutlined,
} from "@ant-design/icons";

import "./SideNavPageLayout.scss";
import { Logo } from "shared/components";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { UserMenu } from "dashboard/components";
import { SearchBox } from "search/components";
import { useBoundStore } from "store";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { AddProjectModal } from "projects/components";
import { useAuth } from "auth/hooks";

type Props = {
  title?: string;
  defaultSelectedMenuItemKey?: string;
  children?: ReactNode;
};

export const SideNavPageLayout = ({
  title = "Dashboard",
  defaultSelectedMenuItemKey = "dashboard",
  children,
}: Props) => {
  const [selected, setSelected] = useState(defaultSelectedMenuItemKey);
  const [collapsed, setCollapsed] = useState(false);
  const [showAddNewProjectModal, setShowAddNewProjectModal] = useState(false);
  const user = useBoundStore((state) => state.user);
  const navigate = useNavigate();
  const { hasPermissions } = useAuth();

  let menuItems = [
    {
      key: "dashboard",
      icon: <HomeFilled />,
      label: "Dashboard",
      title: "Dashboard",
    },
  ];

  if (hasPermissions(["project_get"])) {
    menuItems.push({
      key: "projects",
      icon: <ProjectFilled />,
      label: "Projects",
      title: "Projects",
    });
  }

  if (hasPermissions(["project_create"])) {
    menuItems.push({
      key: "addNewProject",
      icon: <PlusCircleFilled />,
      label: "Add New Project",
      title: "Add New Project",
    });
  }

  if (hasPermissions(["user_admin_create"])) {
    menuItems.push({
      key: "admin",
      icon: <UserOutlined />,
      label: "User Administration",
      title: "User Administration",
    });
  }

  const onMenuSelectHandler = (e: any) => {
    const key = e?.key?.toLowerCase() ?? "";
    const link = `/${key}`;

    if (key === "addnewproject") {
      setShowAddNewProjectModal(true);
      return;
    }

    navigate(link);
  };

  const onAddNewProjectModalClose = () => {
    setShowAddNewProjectModal(false);

    if (defaultSelectedMenuItemKey === "projects") {
      navigate(0);
    }
  };

  useEffect(() => {
    if (showAddNewProjectModal) {
      setSelected("addNewProject");
    } else {
      if (defaultSelectedMenuItemKey !== selected)
        setSelected(defaultSelectedMenuItemKey);
    }
  }, [showAddNewProjectModal, defaultSelectedMenuItemKey]);

  return (
    <>
      <Layout className='side-nav-page-layout-container'>
        <Sider
          width={300}
          className='side-nav-page-layout-sidebar'
          trigger={null}
          collapsible
          collapsed={collapsed}
          theme='light'
        >
          <div className='side-nav-page-layout-logo-container'>
            <Logo className='side-nav-page-layout-logo' collapsed={collapsed} />
          </div>
          <Menu
            theme='light'
            mode='inline'
            selectedKeys={[selected]}
            items={menuItems}
            onSelect={onMenuSelectHandler}
          />
        </Sider>
        <Layout className='side-nav-page-layout-top-bar-container'>
          <Header
            style={{
              padding: 0,
              background: "#f8f8f8",
            }}
          >
            <Row>
              <Col
                className='side-nav-page-layout-menu-collapse-button-container'
                span={1}
              >
                <Button
                  className='side-nav-page-layout-menu-collapse-button'
                  type='text'
                  size='large'
                  icon={collapsed ? <MenuOutlined /> : <MenuOutlined />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    width: 64,
                    height: 64,
                    paddingTop: 0,
                  }}
                />
              </Col>
              <Col span={6}>
                <Typography.Title level={1}>{title}</Typography.Title>
              </Col>
              <Col span={6}>
                <SearchBox />
              </Col>
              <Col
                span={4}
                offset={7}
                className='side-nav-page-layout-user-menu-container'
              >
                <Space>
                  {isEmpty(user?.profilePicture) ? (
                    <Avatar
                      className='side-nav-page-layout-menu-avatar'
                      size={48}
                      icon={<UserOutlined />}
                    />
                  ) : (
                    <Image
                      className='side-nav-page-layout-menu-profile-picture'
                      src={user.profilePicture}
                      preview={false}
                    />
                  )}
                  <UserMenu />
                </Space>
              </Col>
            </Row>
          </Header>
          <Content className='side-nav-page-layout-content-container'>
            <AddProjectModal
              show={showAddNewProjectModal}
              onClose={onAddNewProjectModalClose}
            />
            {children}
          </Content>
        </Layout>
      </Layout>
      <Footer
        style={{
          textAlign: "center",
          backgroundColor: "#000000",
          color: "#ffffff",
        }}
      >
        Terms & Conditions and Privacy Policies
        <Row>
          <Col span={4}></Col>
          <Col span={2}>TracPro</Col>
          <Col span={12}>© 2024 M&M Energy Solutions. All Rights Reserved</Col>
          <Col span={3}>
            <a
              href='https://mmenergysolutions.com/privacy-policy/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Privacy Policy
            </a>
          </Col>
          <Col span={3}>
            <a
              href='https://mmenergysolutions.com/terms-and-conditions/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Terms and Conditions
            </a>
          </Col>
        </Row>
      </Footer>
    </>
  );
};
