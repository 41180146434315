import { StateCreator } from "zustand";

import {
  ICloseoutBySubcontractor,
  IDeficientProject,
  IEngineeringProjects,
  IInstallationBySubcontractor,
  IInstallationsRecap,
  IOutstandingContractorQuote,
  IProjectByStage,
  IProjectDetails,
  IProjectFilter,
  IProjectInCloseoutStage,
  IProjectSlice,
  IProjectTimeTrackingSummary,
  ISubcontractorProjects,
} from "../models";

const initialState = {
  showArchived: false,
  stage: "",
  filters: {
    projectStatus: [],
    subcontractor: [],
    utilityProgram: [],
    salesRep: [],
    customerPaymentMethod: [],
  },
  projectsByStage: [],
  projectsTimeTrackingSummary: [],
  deficientProjects: [],
  hvacInstallationsBySubcontractor: [],
  lightingInstallationsBySubcontractor: [],
  outstandingContractorQuotes: [],
  installationsBySubcontractor: [],
  closeoutsBySubcontractor: [],
  projectsInCloseoutStage: [],
  projectsDetails: [],
  engineeringProjects: {
    projectsInAuditingStage: 0,
    projectsInEngineeringPrepStage: 0,
    projectsInEngineeringStage: 0,
  },
  installationsRecap: {
    totalProjects: 0,
    hvacBacklog: 0,
    lightingBacklog: 0,
    refBacklog: 0,
    totalProjectsInCloseout: 0,
    totalProjectsInCloseoutRevenue: 0,
  },
  subcontractorProjects: {
    totalProjects: 0,
    totalProjectsValue: 0,
  },
};

export const createProjectSlice: StateCreator<
  IProjectSlice,
  [["zustand/devtools", never]],
  [],
  IProjectSlice
> = (set) => ({
  ...initialState,
  setShowArchived: (showArchived: boolean) =>
    set((state) => ({
      showArchived,
    })),
  setStage: (stage: string) =>
    set((state) => ({
      stage: stage,
    })),
  setFilters: (data: IProjectFilter) =>
    set((state) => ({
      filters: data,
    })),
  clearFilters: () =>
    set((state) => ({
      filters: {
        projectStatus: [],
        subcontractor: [],
        utilityProgram: [],
        salesRep: [],
        customerPaymentMethod: [],
      },
    })),
  setProjectsByStage: (projectsByStage: IProjectByStage[]) =>
    set((state) => ({
      projectsByStage: projectsByStage,
    })),
  setProjectsTimeTrackingSummary: (
    projectsTimeTrackingSummary: IProjectTimeTrackingSummary[]
  ) =>
    set((state) => ({
      projectsTimeTrackingSummary: projectsTimeTrackingSummary,
    })),
  setDeficientProjects: (deficientProjects: IDeficientProject[]) =>
    set((state) => ({
      deficientProjects: deficientProjects,
    })),
  setHvacInstallationsBySubcontractor: (
    hvacInstallationsBySubcontractor: IInstallationBySubcontractor[]
  ) =>
    set((state) => ({
      hvacInstallationsBySubcontractor: hvacInstallationsBySubcontractor,
    })),
  setLightingInstallationsBySubcontractor: (
    lightingInstallationsBySubcontractor: IInstallationBySubcontractor[]
  ) =>
    set((state) => ({
      lightingInstallationsBySubcontractor:
        lightingInstallationsBySubcontractor,
    })),
  setOutstandingContractorQuotes: (
    outstandingContractorQuotes: IOutstandingContractorQuote[]
  ) =>
    set((state) => ({
      outstandingContractorQuotes: outstandingContractorQuotes,
    })),
  setInstallationsBySubcontractor: (
    installationsBySubcontractor: IInstallationBySubcontractor[]
  ) =>
    set((state) => ({
      installationsBySubcontractor: installationsBySubcontractor,
    })),
  setCloseoutsBySubcontractor: (
    closeoutsBySubcontractor: ICloseoutBySubcontractor[]
  ) =>
    set((state) => ({
      closeoutsBySubcontractor: closeoutsBySubcontractor,
    })),
  setProjectsInCloseoutStage: (
    projectsInCloseoutStage: IProjectInCloseoutStage[]
  ) =>
    set((state) => ({
      projectsInCloseoutStage: projectsInCloseoutStage,
    })),
  setProjectsDetails: (projectsDetails: IProjectDetails[]) =>
    set((state) => ({
      projectsDetails: projectsDetails,
    })),
  setEngineeringProjects: (engineeringProjects: IEngineeringProjects) =>
    set((state) => ({
      engineeringProjects: engineeringProjects,
    })),
  setInstallationsRecap: (installationsRecap: IInstallationsRecap) =>
    set((state) => ({
      installationsRecap: installationsRecap,
    })),
  setSubcontractorProjects: (subcontractorProjects: ISubcontractorProjects) =>
    set((state) => ({
      subcontractorProjects: subcontractorProjects,
    })),
  resetProjectSlice: () => set(() => initialState),
});
