import { StateCreator } from "zustand";

import { ISimpleUser, IUserSlice } from "../models";
import { ISelectOption } from "shared/models";

const initialState = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: undefined,
    configuration: undefined,
  },
  userMentionOptions: [],
};

export const createUserSlice: StateCreator<
  IUserSlice,
  [["zustand/devtools", never]],
  [],
  IUserSlice
> = (set) => ({
  ...initialState,
  setUser: (user: ISimpleUser) =>
    set((state) => ({
      user: {
        ...state.user,
        ...user,
      },
    })),
  setUserMentionOptions: (userMentionOptions: ISelectOption[]) =>
    set((state) => ({
      userMentionOptions: [...state.userMentionOptions, ...userMentionOptions],
    })),
  resetUserSlice: () => set(() => initialState),
});
