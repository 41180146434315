import { Avatar, Badge, List } from "antd";
import { UserOutlined } from "@ant-design/icons";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

import dayjs from "dayjs";
import VirtualList from "rc-virtual-list";

import "./ProjectNotes.scss";
import { INote } from "notes/models";

dayjs.extend(utc);
dayjs.extend(relativeTime);

type Props = {
  notes: INote[];
};

export const ProjectNotes = ({ notes }: Props) => {
  return (
    <div className='project-notes-container'>
      <List itemLayout='horizontal'>
        <VirtualList
          data={notes}
          className='project-notes-list-container'
          itemHeight={47}
          itemKey='id'
        >
          {(note) => (
            <List.Item className='project-notes-list-item'>
              <List.Item.Meta
                avatar={
                  <Badge dot status='success' size='default' offset={[-6, 35]}>
                    <Avatar
                      shape='circle'
                      size='large'
                      src={note?.createdByProfilePicture}
                      icon={<UserOutlined />}
                    />
                  </Badge>
                }
                title={note?.createdByName}
                description={
                  <span className='project-notes-list-item-time'>
                    {dayjs(note?.createdDate).fromNow()}
                  </span>
                }
              />
              {note?.content}
            </List.Item>
          )}
        </VirtualList>
      </List>
    </div>
  );
};
