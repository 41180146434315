import { useState } from "react";
import { projectService } from "projects/services";
import { ISelectOption } from "shared/models";

export const useGetProjectOptions = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<ISelectOption[]>([]);
  const [error, setError] = useState<Error | undefined>(undefined);

  const getProjectOptions = async (projectName: string) => {
    try {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      setData([]);

      const projectsOptions =
        await projectService.getProjectOptions(projectName);

      setData(
        projectsOptions.map((p) => ({
          id: p?.value?.toString() ?? "",
          value: p?.label?.toString() ?? "",
          label: p?.label?.toString() ?? "",
        }))
      );
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getProjectOptions,
    loading,
    success,
    error,
    data,
  };
};
