import { SubcontractorTypes } from "subcontractors/constants";
import { ISimpleSubcontractor } from "subcontractors/models";
import { ISubcontractorSlice } from "subcontractors/models/ISubcontractorSlice";
import { StateCreator } from "zustand";

const initialState = {
  lightingSubcontractorsSelectOptions: [],
  hvacSubcontractorsSelectOptions: [],
  refrigerationSubcontractorsSelectOptions: [],
};

export const createSubcontractorSlice: StateCreator<
  ISubcontractorSlice,
  [["zustand/devtools", never]],
  [],
  ISubcontractorSlice
> = (set) => ({
  ...initialState,
  setSubcontractorsSelectOptions: (subcontractors: ISimpleSubcontractor[]) =>
    set((state) => ({
      lightingSubcontractorsSelectOptions: subcontractors
        ?.filter((s) => s.type?.toLowerCase() === SubcontractorTypes.Lighting)
        ?.map((s) => ({
          label: s?.name,
          value: s?.id,
        })),
      hvacSubcontractorsSelectOptions: subcontractors
        ?.filter((s) => s.type?.toLowerCase() === SubcontractorTypes.HVAC)
        ?.map((s) => ({
          label: s?.name,
          value: s?.id,
        })),
      refrigerationSubcontractorsSelectOptions: subcontractors
        ?.filter(
          (s) => s.type?.toLowerCase() === SubcontractorTypes.Refrigeration
        )
        ?.map((s) => ({
          label: s?.name,
          value: s?.id,
        })),
    })),
  resetSubcontractorsSlice: () => set(() => initialState),
});
